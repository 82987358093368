@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


.summary {
  margin-bottom: $spacer * 1.5;

  @include media-breakpoint-up(sm) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: $spacer * 1.5;
  }
}