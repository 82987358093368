@import "../../../styles/_theme.scss";


.Grid {
  display: grid;

  @include media-breakpoint-up(md) { grid-template-columns: repeat(2, 1fr); }
  @include media-breakpoint-up(lg) { grid-template-columns: repeat(3, 1fr); }

  column-gap: $grid-gutter-width;
}