.HelpPanel {
  position: relative;
}

.CloseButton {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  line-height: 1;
  color: #bbb !important;
}
