.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  flex: 1 0 auto;
}

.Footer {
  flex: 0 1 auto;
}