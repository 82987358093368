@import "../../styles/theme";

.TermsAndConditions {
  min-height: 320px;
  max-height: 320px;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 20px #ccc;
}
