// Override default variables before the import
$white:    #fff !default;
$gray-100: #fcfcfc !default;
$gray-200: #f8f9fa !default;
$gray-300: #e9ecef !default;
$gray-400: #dee2e6 !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// $body-bg: #000;
$theme-colors: (
  "primary": #f68e20,
) !default;

$spacer: 1rem;

$body-bg: $gray-100;
$input-bg: $gray-100;
$input-disabled-bg: $gray-300;

// $font-size-root: null !default;
$font-size-base: 0.875rem; // Assumes the browser default, typically `16px`

$input-btn-padding-x-sm: 0.75rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-x-lg: 1.5rem;

$btn-border-radius-sm: 2rem;
$btn-border-radius: 2rem;
$btn-border-radius-lg: 2rem;

$input-line-height: 2.0;
$headings-margin-bottom: $spacer;

// $input-border-color: #dadadb;
// $input-placeholder-color: #dadadb;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow:                  0 .25rem .5rem rgba($black, .15);
$box-shadow-lg:               0 .5rem 1rem rgba($black, .175);

// FONTS
$font-family-sans-serif: 'Roboto', sans-serif;
$link-decoration: none;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-light;


// $fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
// $caret-vertical-align: middle;
$enable-caret: false;

// @import "~@fortawesome/fontawesome-pro/scss/fontawesome";
// @import "~@fortawesome/fontawesome-pro/scss/light";
// @import "~@fortawesome/fontawesome-pro/scss/larger";

// @import '~bootstrap/scss/bootstrap.scss';
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
// @import "mixins";

$form-feedback-validating-color: $secondary;
$form-feedback-icon-validating: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#{$form-feedback-validating-color}' d='M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z'/></svg>");

$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  ),
  "validating": (
    "color": $form-feedback-validating-color,
    "icon": $form-feedback-icon-validating
  )
)