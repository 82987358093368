
@import "../../styles/_theme.scss";

.PortalLogo {
  max-width: 120px;
  // max-width: 190px;
  display: block;
  // margin: 0 auto;
  text-align: left;
  img {
    max-height: 35px;
    // @include media-breakpoint-up(md) {
    //   max-height: 40px;
    // }
  }
}

.NavItemsContainer {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.MenuItemStack {
  flex: 0 0 auto;
}