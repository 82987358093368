.simpleAddressText {
  flex: 0 1 auto;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simpleAddressButton {
  flex: 0 1 auto;
  padding: 0.25rem;
}