@import "../../styles/theme";

.FormField {
  position: relative;
  input {
    z-index: unset; // when in input-group, this gets set to 3 which affects the label
  }
  label {
    position: absolute;
    background: $input-bg;
    color: darken($input-border-color, 20%);

    transition-property: opacity, top, left, font-size, color;
    transition-timing-function: ease-in-out;
    transition-duration: 0.09s;
  }
  label {
    opacity: 0;
    z-index: -1;
    top: 17%;
    left: $input-padding-x / 2;
    left: 1.1rem;
    font-size: unset;
  }

  // input:focus {
  // background: red !important;
  // }
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label,
  select.HasValue ~ label,
  label.visibleLabel {
    opacity: 1;
    left: $input-padding-x / 1;
    padding-left: $input-padding-x / 2;
    padding-right: $input-padding-x / 2;
    top: -7px;
    z-index: 10;
    font-size: $small-font-size;
    pointer-events: none;
  }
  input,
  textarea {
    &:focus::placeholder{
      opacity: 0;
      color: transparent;
    }
  }
  textarea {
    line-height: 1.4;
  }


  // label {

  //   left: $input-padding-x / 1;
  //   padding-left: $input-padding-x / 2;
  //   padding-right: $input-padding-x / 2;
  //   top: -25%;
  //   z-index: unset;
  //   font-size: $small-font-size;
  // }

  // input:placeholder-shown ~ label{
  //   opacity: 00;
  //   top: 25%;
  //   left: $input-padding-x / 2;
  //   z-index: -1;
  //   font-size: unset;
  // }
}
